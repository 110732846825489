/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: #171426be !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
  text-align: center !important;
  z-index: 100 !important;
}

.footer-copywright,
.footer-body {
  text-align: center !important;
}

.footer h3 {
  font-size: 1.1em;
  color: white !important;
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 15px; 
  color: white !important;
  padding: 0 !important;
  font-size: 1.6em;
}

.footer a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease-in-out;
}

.footer a:hover {
  color: #286EEF !important;
}

@media (max-width: 767px) {
  .footer h3 {
    font-size: 1em;
    margin: 0.3em 0 !important;
  }

  .footer-icons {
    font-size: 1.4em;
    gap: 12px;
  }
}
