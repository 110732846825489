.preload-message {
    font-size: 2.5em;
}

.sphere {
    border-radius: 50%;
    position: absolute;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.red-sphere {
    width: 100px;
    height: 100px;
    background: radial-gradient(circle at 30% 30%, #f56470, #dd1a1a, #990202);
    box-shadow: 0px 0px 25px rgba(255, 46, 46, 0.8), inset 0px 0px 10px rgba(255, 255, 255, 0.3);
    border: 2px solid rgba(255, 255, 255, 0.2);
    left: calc(50% - 100px);
}


.blue-sphere {
    width: 100px;
    height: 100px;
    background: radial-gradient(circle at 30% 30%, #95caff, #2a6ad8, #02266e);
    box-shadow: 0px 0px 25px rgba(30, 111, 255, 0.8), inset 0px 0px 10px rgba(255, 255, 255, 0.3);
    border: 2px solid rgba(255, 255, 255, 0.2);
    right: calc(50% - 100px);
}


.purple-sphere {
    width: 150px;
    height: 150px;
    background: radial-gradient(circle at 30% 30%, #d28eff, #7826ca, #5a02c5);
    box-shadow: 0px 0px 25px #a522fc8e, inset 0px 0px 15px rgba(255, 255, 255, 0.3);
    border: 2px solid rgba(255, 255, 255, 0.2);
    left: calc(50% - 75px);
}



.video-container {
    width: 100%;
    max-width: 1000px;
    height: auto;

}

.embed-responsive-item {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-bottom: 20%;
}


.logo {
    height: auto !important;
    width: 2em !important;
    display: block;
    position: relative;
    cursor: grab !important;
}


.logo-white {
    position: absolute;
    width: 100%;
    height: auto;
    opacity: 0;
    cursor: grab !important;
}


/* Effet bleu après 2 secondes */
.logo-container.start-border-animation {
    animation: imgTurn 8s linear infinite 2s;
}



/* Animation de rotation de la bordure bleu */
@keyframes imgTurn {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Popup */
.modal-header-grey,
.modal-footer-grey {
    background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
    color: white;
}

.modal-body-grey {
    background: linear-gradient(90deg, rgba(255, 0, 0, 0.3), rgba(255, 165, 0, 0.3), rgba(255, 255, 0, 0.3), rgba(0, 128, 0, 0.3), rgba(0, 0, 255, 0.3), rgba(75, 0, 130, 0.3), rgba(238, 130, 238, 0.3));
}

.modal-header .btn-close {
    padding-left: 2em;
    opacity: 0.7;
}

.modal-header .btn-close:hover {
    opacity: 1;
}