/* Variables Globales */
html {
  --section-background-color: linear-gradient(to bottom left, rgba(17, 16, 16, 0.582), rgba(12, 8, 24, 0.904));
  --image-gradient: linear-gradient(to bottom left, rgba(17, 16, 16, 0.678), rgba(12, 10, 22, 0.863));
  --imp-text-color: #52a4f1;
}

/* Global Styles */
body {
  overflow-x: hidden !important;
}

/* Couleurs de texte */
.dark-blue {
  color: #276DEE !important;
}

.blue-title {
  color: #276DEE !important;
  font-family: 'Montserrat', serif  !important;
  font-weight: 700;
}

.light-blue-title {
  color: #5c92f5 !important;
  font-family: 'Montserrat', serif  !important;
  font-weight: 700;
}

.title-font {
  font-family: 'Montserrat', serif !important;
  font-weight: 700;
}

.title-font-easter {
  font-family: 'Permanent Marker', cursive !important;
}
.blue {
  color: #88b9f8
}

.darkblue {
  color: #276DEE !important;
}

/* Boutons */
.btn:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.button-youtube, .button-cv {
  border-radius: 15px !important;
  color: white !important;
  font-size: 1.1em !important;
  max-width: 250px !important;
  transition: all 0.3s ease-in-out !important;
  z-index: 1 !important;
}

.button-youtube {
  background-color: #bd1903 !important;
  border: 1px solid white !important;
}

.button-youtube:hover {
  background-color: #ffd4cf !important;
  border: 2px solid #bd1903 !important;
  color: #bd1903 !important;
}

.button-cv {
  background-color: #1c53b8 !important;
  border: 2px solid white !important;
}

.button-cv:hover {
  background-color: #d8e6ff !important;
  border: 2px solid #1c53b8 !important;
  color: #1c53b8 !important;
}

.text-justify {
  text-align: justify !important;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 7px !important;
}

::-webkit-scrollbar-track {
  background: #2d1950 !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(121, 151, 216, 0.959) !important;
  border-radius: 12px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(142, 171, 233, 0.911) !important;
  border-radius: 12px !important;
}

/* Sections */
.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

/* Background Box */
.background-box {
  background-color: rgba(71, 70, 70, 0.267) !important;
  padding: 2em !important;
  border: 2px solid rgba(255, 255, 255, 0.6) !important;
  border-radius: 15px !important;
}
