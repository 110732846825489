#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(../../images/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
  transition: opacity 0.5s ease;
}

#preloader.fade-out {
  opacity: 0;
  pointer-events: none;
}